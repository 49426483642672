var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.country)}})]}},{key:"item.sector",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.sector)}})]}},{key:"item.purpose",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.purpose)}})]}},{key:"item.bio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.bio)}})]}},{key:"item.otherPolicies",fn:function(ref){
var item = ref.item;
return [(item.otherPoliciesText)?_c('div',{staticStyle:{"font-size":"12px","font-family":"Roboto"}},[_vm._v(" "+_vm._s(item.otherPoliciesText)+" ")]):_vm._e(),(_vm.getOtherPolicies(item.otherPolicies).length > 1)?_c('ul',_vm._l((_vm.getOtherPolicies(item.otherPolicies)),function(policy,i){return _c('li',{key:i},[_c('div',{staticStyle:{"font-size":"12px","font-family":"Roboto"},domProps:{"innerHTML":_vm._s(policy)}})])}),0):_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getOtherPolicies(item.otherPolicies)[0])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="border"
      :items-per-page="5"
    >
      <template #item.title="{ item }">
        <div style="font-size: 12px;" v-html="item.title"></div>
      </template>

      <template #item.country="{ item }">
        <div style="font-size: 12px" v-html="item.country"></div>
      </template>

      <template #item.sector="{ item }">
        <div style="font-size: 12px" v-html="item.sector"></div>
      </template>

      <template #item.purpose="{ item }">
        <div style="font-size: 12px" v-html="item.purpose"></div>
      </template>

      <template #item.bio="{ item }">
        <div style="font-size: 12px" v-html="item.bio"></div>
      </template>

      <template #item.otherPolicies="{ item }">
        <div
          style="font-size: 12px; font-family:Roboto"
          v-if="item.otherPoliciesText"
        >
          {{ item.otherPoliciesText }}
        </div>
        <ul v-if="getOtherPolicies(item.otherPolicies).length > 1">
          <li
            v-for="(policy, i) in getOtherPolicies(item.otherPolicies)"
            :key="i"
          >
            <div
              style="font-size: 12px; font-family:Roboto"
              v-html="policy"
            ></div>
          </li>
        </ul>
        <div style="font-size: 12px" v-else>
          {{ getOtherPolicies(item.otherPolicies)[0] }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<i18n>
{
    "en": {
        "title": "Title",
        "countryRegion": "Country/Region",
        "sector": "Sector",
        "purpose": "Purpose of the instrument with regard to the biomass value chain",
        "bioeconomySystem": "Bioeconomy system component addressed",
        "otherPolicies": "Other policies described in same fact sheet",
        "items": [
            {
                "title": "1. Austrian landfill tax, known as the ‘Altlastensanierungsbeitrag’ (‘ALSAG’) Abfallvermei-dungsprogramm - Waste Prevention Programmes",
                "country": "Austria",
                "sector": "ALSAG: Waste, industry <br> Waste prevention programme: Households & economic sectors",
                "purpose": "Limiting environmental impact <br> Mobilise biomass by financial incentive",
                "bio": "End of life  ",
                "otherPolicies": [
                    "Austrian recycling of waste wood directive",
                    "24 other countries have a landfill tax instrument<br>Examples of appointment of waste advisors also exist in Germany, UK and Belgium."
                ]
            },
            {
                "title": "2 Pay As You Throw (PAYT) scheme Dutch municipalities",
                "country": "Netherlands, but examples of other BNELUX countries discussed too",
                "sector": "Households, municipalities, waste",
                "purpose": "Limiting environmental impact <br> Mobilise biomass by financial incentive",
                "bio": "End of life, organise the recycling of waste",
                "otherPolicies": [
                    "Many PAYT systems exist now adays in EU countries. Longer term experiences discussed here are from Belgium, and Luxembourg "
                ]
            },
            {
                "title": "3. Cluster Initiative Bavaria",
                "country": "Germany - Bavaria",
                "sector": "Industries, R&D",
                "purpose": "Enabling environment: Fostering research collaboration",
                "bio": "Whole biomass value chain",
                "otherPolicies": [
                    "There are many smart specialisation cluster initiatives in EU such as Bio-based Delta in the Netherlands, BioVale in the UK, IAR in France.  "
                ]
            },
            {
                "title": "4. Stimulation of Sustainable Energy Production - Stimulering Duurzame Energieproductie (SDE+) in Netherlands",
                "country": "Netherlands, but FITs in other countries also discussed",
                "sector": "Energy",
                "purpose": "Processing: Decreasing financial risk to produce renewable energy due to market fluctuation",
                "bio": "Processing",
                "otherPolicies": [
                    "Feed-in tariff systems exist in almost every EU country. The other example in the long list and discussed in from Germany and Hungary."
                ]
            },
            {
                "title": "5. Ordinance on the Generation of Electricity from Biomass (Biomass Ordinance - BiomasseV) in Germany",
                "country": "Germany",
                "sector": "Energy",
                "purpose": "Mobilisation of biomass by regulating <br> Sustainable harvesting of biomass/limiting environmental impact",
                "bio": "Biomass, Processing",
                "otherPoliciesText": "The whole package of policies in Germany in relation to renewble energy is discussed in fact sheet: covering other policies in the long list such as:",
                "otherPolicies": [
                    "Renewable Energies Heat Act (EEWärmeG)",
                    "Renewable Energy Sources Act (Erneuerbare-Energien-Gesetz – EEG)",
                    "Biomass Electricity – Sustainable Development Ordinance (Biomassestrom-Nachhaltigkeitsverordnung – BioSt-NachV)"
                ]
            },
            {
                "title": "6. Act on the Carbon Dioxide Tax on Certain Energy Products in Denmark",
                "country": "Denmark, but other CO2 taxation systems also discussed",
                "sector": "Energy",
                "purpose": "Convince consumers to make use of bioenergy by decreasing costs/making bioproducts more attractive financially",
                "bio": "Processing, End use",
                "otherPolicies": [
                    "At EU level there is the ETS systems and the relation with this instrument is discussed",
                    "There are many countries in the EU and outside EU that tax CO2 in the energy system. Often this is combined with energy taxation systems. This is discussed in the fact sheet."
                ]
            },
            {
                "title": "7. Regulation on the use of biomass from forest for energy (Orden 29/12/2011)",
                "country": "Spain - Andalusia",
                "sector": "Energy, Forestry",
                "purpose": "- Sustainable harvesting of biomass/limiting environmental impact<br> - Mobilisation of biomass by regulating",
                "bio": "Forest & Renewable energy",
                "otherPolicies": [
                    "There are more countries in the EU that specifically regulate the use of different forest biomass resources for energy. An overview of countries and systems that regulate more strictly the sustainable use of solid biomass resources for energy is given . "
                ]
            },
            {
                "title": "8. Regulation of the use of residual biomass from olive oil industries (D 4/2011) Andalusia",
                "country": "Spain - Andalusia",
                "sector": "Agriculture",
                "purpose": "Mobilisation of biomass by regulating",
                "bio": "Biomass, end of life use",
                "otherPolicies": [
                    "Similar policy instrumentsare discussed that occur in other Spanish autoomous regions and in other southern European countries. "
                ]
            },
            {
                "title": "9. Biomethane Decree",
                "country": "Italy",
                "sector": "Energy",
                "purpose": "Convince consumers to make use of bioenergy by decreasing costs/making bioproducts more attractive financially <br> Mobilise biomass by financial incentive",
                "bio": "Biomass, end of life use",
                "otherPolicies": [
                    "In Denmark the economic and political environment focuses on the use of biomethane in Combined Heat and Power (CHP) units"
                ]
            },
            {
                "title": "10. Bioeconomy Technological Platform Piemonte (Smart Specialisation Strategy)",
                "country": "Italy - Piemonte",
                "sector": "Agriculture, Industry (chemistry), R&D",
                "purpose": "Enabling environment: Fostering industry – research collaboration particularly in bio-materials and chemicals",
                "bio": "Whole biomass value chain",
                "otherPolicies": [
                    "There are many smart specialisation cluster initiatives in EU such as the other example presented as good example policy in Bavaria, Bio-based Delta in the Netherlands, BioVale in the UK, IAR in France.  "
                ]
            }
        ]
    }
}
</i18n>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: this.$t("title"),
          value: "title",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false,
          width: "15%"
        },
        {
          text: this.$t("countryRegion"),
          value: "country",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false,
          width: "10%"
        },
        {
          text: this.$t("sector"),
          value: "sector",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: this.$t("purpose"),
          value: "purpose",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: this.$t("bioeconomySystem"),
          value: "bio",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false,
          width: "10%"
        },
        {
          text: this.$t("otherPolicies"),
          value: "otherPolicies",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false,
          width: "30%"
        }
      ]
    };
  },
  computed: {
    items() {
      return Object.values(this.$t("items"));
    }
  },
  methods: {
    getOtherPolicies(policies) {
      return Object.values(policies);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";

.border {
  border: 1px solid $light_green;
}
</style>
